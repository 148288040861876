
















































































































































































































import { Vue, Component } from 'vue-property-decorator'
import { apiMpWeChatMenuDetail, apiMpWeChatMenuSave, apiMpWeChatMenuPublish } from '@/api/channel/mp_wechat'
import { MPWeChatMenu } from '@/api/channel/mp_wechat.d.ts'
import LsDialog from '@/components/ls-dialog.vue'
import MPWechatMenuForm from '@/components/channel/mp-wechat/menu-form.vue'

interface MenuItem extends MPWeChatMenu {
    has_menu: boolean
}

interface MenuFrom {
    menu: Array<MenuItem>
}

@Component({
    components: {
        LsDialog,
        MPWechatMenuForm,
    },
})
export default class MPWechatMenu extends Vue {
    /** S Data **/
    form: MenuFrom = {
        menu: [],
    }

    rules = {
        name: [
            {required: true, message: '必填项不能为空', trigger: ['blur', 'change']},
            { min: 1, max: 12, message: '长度限制12个字符', trigger: ['blur', 'change'] }
        ],
    }

    /** E Data **/


    /** S Methods **/
    // 添加主菜单
    onMenuAdd() {
        if (this.form.menu.length >= 3)
            return this.$message.info('主菜单仅限有3项!')
        this.form.menu.push({
            name: '',
            type: '',
            has_menu: false,
            key: '', // 关键字
            url: '', // 网页URL
            appid: '', // 小程序AppID
            pagepath: '', // 小程序路径
            sub_button: [], // 二级菜单
        })
    }


    // 添加子菜单
    handleSubMenuAdd(menu: any, index: number) {
        const refForm: any = this.$refs[`SubMenuFormAdd-${index}`]
        const refDialog: any = this.$refs[`SubMenuDialogAdd-${index}`]

        const { valid, data } = refForm[0].validateForm()
        if (!valid) return this.$message.error('表单验证失败!')

        menu.sub_button.push({ ...data })
        this.$message.success('添加成功')

        refForm[0].clearFrom()
        refDialog[0].close()
    }

    // 子菜单编辑
    onSubMenuEdit(index: number, subIndex: number) {
        const refForm: any = this.$refs[`SubMenuFormEdit-${index}-${subIndex}`]
        const refDialog: any = this.$refs[`SubMenuDialogEdit-${index}-${subIndex}`]

        const { valid, data } = refForm[0].validateForm()
        if (!valid) return this.$message.error('表单验证失败!')

        const parent = (this.form?.menu[index] as any).sub_button
        this.$set(parent, subIndex, {...data})
        this.$message.success('修改成功')

        refForm[0].clearFrom()
        refDialog[0].close()
    }

    // 删除菜单
    handleMenuDel(index: number) {
        this.form.menu.splice(index, 1)
    }

    // 删除子菜单
    handleSubMenuDel(index: number, subIndex: number) {
        const menu: any = this.form.menu[index]
        menu.sub_button.splice(subIndex, 1)
    }

    // 初始化菜单数据
    initMPWeChatMenuData() {
        return new Promise ((resovle, reject) => {
            apiMpWeChatMenuDetail().then((data: any) => {
                data.map((item: any) => {
                    item.has_menu = !!item.sub_button?.length
                })
                this.$set(this.form, 'menu', data)
            })
        })
    }

    // 表单保存
    onFromSave() {
        apiMpWeChatMenuSave({
            ...this.form
        }).then(data => {
            this.initMPWeChatMenuData()
            this.$message.success('保存成功')
        }).catch(err => {
            // this.$message.error('保存失败')
        })
    }

    // 保存并发布
    onFromPublish() {
       apiMpWeChatMenuPublish({
            ...this.form
        }).then(data => {
            this.initMPWeChatMenuData()
            this.$message.success('发布成功')
        }).catch(err => {
            // this.$message.error('发布失败')
        })
    }
    /** E Methods **/


    /** S Life Cycle **/
    created() {
        this.initMPWeChatMenuData()
    }

    /** E Life Cycle **/
}
